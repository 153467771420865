/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import extend from 'lodash-es/extend';
import { AuthService } from '@fyle/auth';
import { ExtendedOrgUser } from '@fyle/model-shared';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(
    private authService: AuthService,
    private window: Window,
  ) {}

  private isDemoAccount(eou: ExtendedOrgUser): boolean {
    const email = eou.us.email.toLowerCase();
    const orgName = eou.ou.org_name.toLowerCase();
    const keywords = ['demo', 'test', 'fyle'];

    return keywords.some((keyword) => email.includes(keyword) || orgName.includes(keyword));
  }

  private getUserProperties(): Record<string, string | boolean> {
    const properties: Record<string, string | boolean> = {};
    const eou = this.authService.getEou();
    // Replace any with proper type
    if (eou?.ou) {
      properties['User Id'] = eou.us.id;
      properties['Org Id'] = eou.ou.org_id;
      properties['Org User Id'] = eou.ou.id;
      properties['Org Currency'] = eou.org.currency;
      properties['Is Demo Account'] = this.isDemoAccount(eou);
    }
    return properties;
  }

  eventTrack(action: string, properties: Record<string, any> = {}): void {
    properties.Asset = 'webapp';
    extend(properties, this.getUserProperties());
    try {
      if ((this.window as any).mixpanel) {
        (this.window as any).mixpanel.track(action, properties);
      }
    } catch (e) {}
  }
}
